import React from "react";

// styling
import { ContentDiv } from "../HeroSection/HeroSection.styled";
import { ContainerDiv, VideoDiv } from "./ThirdFeature.styled";

import video from "./Assets/Videos/3.mp4";

const ThirdFeature = () => {
  return (
    <ContainerDiv>
      <ContentDiv className="leftSide">
        <h1>
          Use our Tokens <br />
          to Shop
        </h1>
        <p>
          Get access to exclusive in-app deals and discounts in the in-app shop
          with our token.
        </p>
      </ContentDiv>

      <VideoDiv>
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
      </VideoDiv>
    </ContainerDiv>
  );
};

export default ThirdFeature;
