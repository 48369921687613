import styled from "styled-components";

export const ContainerDiv = styled.div`
  display: grid;
  gap: 5%;
  grid-template-columns: 1fr 1fr;
  /* margin-top: 8rem; */

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 6%;
  }

  & .rightSide {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 15%;

    @media screen and (max-width: 768px) {
      padding: 100px 10%;
    }
  }

  & .leftSide {
    padding-left: 15%;
  }

  & .formobile {
    display: none;
    width: 100vw;

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const MobileDiv = styled.div`
  &.forDesktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  width: 304px;
  height: 630px;
  background: #ffffff;
  border: 0.943329px solid #eeeeee;
  box-shadow: 66.0331px 94.3329px 188.666px rgba(0, 0, 0, 0.17),
    inset -9.43329px -18.8666px 28.2999px rgba(0, 0, 0, 0.1),
    inset 9.43329px 18.8666px 28.2999px rgba(0, 0, 0, 0.1);
  border-radius: 40px;

  & .mobile {
    position: relative;
    border: 1px solid #e6e6e6;
    width: 260px;
    height: 580px;
    border-radius: 20px;
    margin: 20px;
    overflow: hidden;
    /* background-color: red; */
  }

  & .mbl {
    position: absolute;
    top: -15px;
    width: 100%;
    height: 600px;
    left: 0;

    video {
      height: 100%;
      width: 100%;
    }
  }

  & .mbltop {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 20px;
  }
`;
