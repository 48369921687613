import styled from "styled-components";

export const ContainerDiv = styled.div`
  margin-top: 5rem;
  background: #13151e;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 150px;
  align-items: center;
  padding: 0 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    gap: 0.45rem;
  }

  p {
    font-size: 14px;
    color: #71717a;
    text-align: center;
  }

  & .copyright {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  & .copyrightMbl {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      margin-top: 0.8rem;
    }
  }
`;

export const IconsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  & .iconDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1.5px solid white;
    width: 28px;
    height: 28px;
    background: transparent;
    cursor: pointer;
    transition: 0.2s ease;

    :hover {
      background: linear-gradient(
        90deg,
        #9e89fe 0%,
        #c38dfd 49.59%,
        #e391ff 100%
      );
      border: none;
    }
  }
`;
