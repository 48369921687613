import React from "react";

// styling
import { ContainerDiv } from "./FirstFeature.styled";
import { ContentDiv } from "../HeroSection/HeroSection.styled";
import { MobileDiv } from "../SecondFeature/SecondFeature.styled";

// video and SVGs
import video from "./Assets/Videos/1.mp4";

const FirstFeature = () => {
  return (
    <ContainerDiv>
      <ContentDiv className="leftSide">
        <h1>
          Swipe Right <br />
          and Match
        </h1>
        <p>
          Swipe right and match with like-minded singles on our marriage app.
          Our unique algorithm suggests potential matches based on your
          interests and preferences. Find your future wife today!
        </p>
      </ContentDiv>

      <div className="rightSide">
        <MobileDiv>
          <div className="mobile">
            <div className="mbl">
              <video autoPlay loop muted>
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </MobileDiv>
      </div>
    </ContainerDiv>
  );
};

export default FirstFeature;
