import "./App.css";

// components
import Navbar from "./Components/Navbar/Navbar";
import HeroSection from "./Components/HeroSection/HeroSection";
import Features from "./Components/Features/Features";
import FirstFeature from "./Components/FirstFeature/FirstFeature";
import SecondFeature from "./Components/SecondFeature/SecondFeature";
import ThirdFeature from "./Components/ThirdFeature/ThirdFeature";
import Footer from "./Components/Footer/Footer";
import JoinWaitlist from "./Components/WaitlistForm/WaitlistForm";

function App() {
  const FormUrl = "https://x19ha9vp6pz.typeform.com/to/WZH3pA0z";
  return (
    <>
      <div id="home" />
      <Navbar FormUrl={FormUrl} />
      <HeroSection FormUrl={FormUrl} />
      <div id="features" />
      <Features />
      <div id="watch" />
      <FirstFeature />
      <SecondFeature />
      <ThirdFeature />
      <JoinWaitlist FormUrl={FormUrl} />
      <Footer />
    </>
  );
}

export default App;
