import React from "react";

// img
import LogoIcon from "./Assets/Images/LogoIcon.png";

// styling
import { ContainerDiv, IconsDiv } from "./Footer.styled";

// SVGs
import FacebookIcon from "./Assets/FacebookIcon";
import InstagramIcon from "./Assets/InstagramIcon";
import TwitterIcon from "./Assets/TwitterIcon";

const Footer = () => {
  const TwitterUrl = "https://twitter.com/wivesofsolomon";
  const InstagramUrl = "https://www.instagram.com/wivesofsolomon/";
  const DiscordUrl = "https://discord.com/invite/GSn3mXWjUd";

  return (
    <ContainerDiv>
      <div>
        <img src={LogoIcon} alt="logo" />
      </div>

      <div className="copyright">
        <p>© Copyright 2022, All Rights Reserved by Wivesofsolomon</p>
      </div>

      <IconsDiv>
        <div
          className="iconDiv"
          onClick={() => window.open(TwitterUrl, "_blank")}
        >
          <TwitterIcon />
        </div>
        <div
          className="iconDiv"
          onClick={() => window.open(DiscordUrl, "_blank")}
        >
          <FacebookIcon />
        </div>
        <div
          className="iconDiv"
          onClick={() => window.open(InstagramUrl, "_blank")}
        >
          <InstagramIcon />
        </div>
      </IconsDiv>

      <div className="copyrightMbl">
        <p>© Copyright 2022, All Rights Reserved by Wivesofsolomon</p>
      </div>
    </ContainerDiv>
  );
};

export default Footer;
