import React, { useState } from "react";

// styled
import { Nav, ToggleDiv } from "./Navbar.styled";

// components
import NavLinks from "./NavLinks/NavLinks";
import LogoIcon from "./Assets/SVGs/LogoIcon";
import NavButton from "./NavButton/NavButton";
import ToggleIcon from "./Assets/SVGs/ToggleIcon";
import CloseIcon from "./Assets/SVGs/CloseIcon";

const Navbar = ({ FormUrl }) => {
  const [toggle, setToggle] = useState(false);
  // const [show, setShow] = useState(true);
  // const [lastScrollY, setLastScrollY] = useState(0);

  // const controlNavbar = () => {
  //   if (typeof window !== "undefined") {
  //     if (window.scrollY > lastScrollY) {
  //       // if scroll down hide the navbar
  //       setShow(true);
  //     } else {
  //       // if scroll up show the navbar
  //       setShow(false);
  //     }

  //     // remember current page location to use in the next move
  //     setLastScrollY(window.scrollY);
  //   }
  // };

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener("scroll", controlNavbar);

  //     // cleanup function
  //     return () => {
  //       window.removeEventListener("scroll", controlNavbar);
  //     };
  //   }
  // });

  return (
    <Nav>
      <LogoIcon />
      <NavLinks toggle={toggle} setToggle={setToggle} />
      <div className="btnDiv">
        <NavButton FormUrl={FormUrl} />
      </div>
      <ToggleDiv onClick={() => setToggle(!toggle)}>
        {!toggle ? <ToggleIcon /> : <CloseIcon />}
      </ToggleDiv>
    </Nav>
  );
};

export default Navbar;
