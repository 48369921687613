import styled from "styled-components";

export const ContainerDiv = styled.div`
  display: grid;
  gap: 10%;
  grid-template-columns: 1fr 1fr;
  margin-top: 8rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 3%;
    margin-top: 10rem;
  }

  & .leftSide {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding-left: 15%;

    @media screen and (max-width: 768px) {
      padding: 0 10%;
    }
  }
`;

export const VideoDiv = styled.div`
  background-color: white;
  padding-right: 15%;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 10%;
    margin: 3.5rem 0;
  }

  video {
    height: 100%;
    width: 100%;
  }
`;
