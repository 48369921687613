import React from "react";

// images
import sideimg from "./Assets/Images/Iphone1.png";
import img from "./Assets/Images/iphonembl.png";

// styling
import {
  ContainerDiv,
  ContentDiv,
  ImageDiv,
  SideImage,
} from "./HeroSection.styled";

// components
import NavButton from "../Navbar/NavButton/NavButton";

const HeroSection = ({FormUrl}) => {
  return (
    <>
      <ContainerDiv>
        <ContentDiv className="contentDiv">
          <h1 className="heading">
            Making <span>Bridges</span> and
            <br /> not Just Babies!
          </h1>
          <p>
            Wives of Solomon is a blockchain Dapp that helps you find your
            perfect match for marriage. With our advanced algorithms and
            innovative approach, we are on the mission to revolutionize how
            people meet, date, and get married.
          </p>
          <div className="btnsDiv">
            <NavButton FormUrl={FormUrl} />
          </div>
        </ContentDiv>

        <SideImage>
          <img src={sideimg} alt="iphone" />
        </SideImage>
      </ContainerDiv>

      <ImageDiv>
        <img src={img} alt="mobile" />
      </ImageDiv>
    </>
  );
};

export default HeroSection;
