import styled from "styled-components";

export const ContainerDiv = styled.ul`
  display: flex;
  gap: 1.2rem;
  align-items: center;

  & .btndiv {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0px;
    right: 0;
    width: 100vw;
    height: 0;
    z-index: 1;
    background: white;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    transition: 0.5s;
    overflow: hidden;
  }

  &.toggleDiv {
    height: 100vh;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #0f172a;
  }
`;
