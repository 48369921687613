import React from "react";

const XPIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_411_100)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7533 25.5509L13.7659 26.5042L8.52563 33H13.1816L17.1176 27.84L19.6376 33H24.4136L20.7533 25.5509ZM27.7175 24.6006L34.0548 23.736H34.2019C34.6453 23.736 35.0361 23.676 35.3742 23.556L40.7515 22.8223C40.5557 23.3278 40.2924 23.8083 39.9619 24.264C39.3699 25.064 38.5619 25.712 37.5379 26.208C36.5299 26.704 35.3379 26.952 33.9619 26.952H31.4179L30.3379 33H26.2339L27.7175 24.6006ZM41.1369 19.7419C40.9985 18.754 40.5668 17.9493 39.8419 17.328C38.9459 16.544 37.6179 16.152 35.8579 16.152H29.2099L28.2656 21.4981L32.4783 20.9233L32.7379 19.416H34.9699C35.6419 19.416 36.1379 19.56 36.4579 19.848C36.6212 19.9802 36.7448 20.1408 36.8288 20.3297L41.1369 19.7419ZM22.1841 22.3278L14.8942 23.3225L11.3816 16.152H16.1576L18.6536 21.24L22.5176 16.152H27.1736L22.1841 22.3278Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_411_100"
          x1="0"
          y1="50"
          x2="50"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E89FE" />
          <stop offset="0.49591" stopColor="#C38DFD" />
          <stop offset="1" stopColor="#E391FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default XPIcon;
