import React from "react";

// styling
import { JoinWaitlistDiv } from "./WaitlistForm.styled";

const JoinWaitlist = ({ FormUrl }) => {
  return (
    <JoinWaitlistDiv>
      <h1>Become a part of our waitlist</h1>
      <div className="btnDiv">
        <button onClick={() => window.open(FormUrl, "_blank")}>Join now</button>
      </div>
    </JoinWaitlistDiv>
  );
};

export default JoinWaitlist;
