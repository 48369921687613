import React from "react";

// components
import NavButton from "../NavButton/NavButton";

// styling
import { ContainerDiv } from "./NavLinks.styled";

// nav links
const linksArray = [
  {
    path: "#home",
    link: "Home",
  },

  {
    path: "#features",
    link: "Features",
  },
  {
    path: "#watch",
    link: "Watch",
  },
];

const NavLinks = ({ toggle, setToggle }) => {
  return (
    <ContainerDiv className={toggle ? "toggleDiv" : null}>
      {linksArray.map((item, index) => {
        return (
          <li key={index} onClick={() => setToggle(!toggle)}>
            <a href={item.path}>{item.link}</a>
          </li>
        );
      })}
      <div className="btndiv">
        <NavButton />
      </div>
    </ContainerDiv>
  );
};

export default NavLinks;
