import React from "react";

// styling
import { ContainerDiv, MobileDiv } from "./SecondFeature.styled";
import { ContentDiv } from "../HeroSection/HeroSection.styled";

import video from "./Assets/Videos/2.mp4";
// import MobileIcon from "../FirstFeature/Assets/SVGs/MobileIcon";

const SecondFeature = () => {
  return (
    <ContainerDiv>
      <div className="leftSide">
        <MobileDiv className="forDesktop">
          <div className="mobile">
            <div className="mbl">
              <video autoPlay loop muted>
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </MobileDiv>
      </div>

      <ContentDiv className="rightSide">
        <h1>
          Find your Courtyard <br />
          Connection
        </h1>
        <p>
          The Courtyard on our dating app allows two users to connect and have a
          private conversation. This feature provides a safe and secure space
          for users to get to know each other and build a deeper connection
          before taking the next step in their relationship.
        </p>
      </ContentDiv>

      <div className="formobile">
        <MobileDiv>
          <div className="mobile">
            <div className="mbl">
              <video autoPlay loop muted>
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </MobileDiv>
      </div>
    </ContainerDiv>
  );
};

export default SecondFeature;
