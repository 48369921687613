import React from "react";

// SVGs
import StarIcon from "./Assets/SVGs/StarIcon";
import MessageIcon from "./Assets/SVGs/MessageIcon";
import RingIcon from "./Assets/Images/RingIcon.png";

// styling
import {
  ContainerDiv,
  ContentDiv,
  HeadingDiv,
  ItemDiv,
} from "./Features.styled";
import XPIcon from "./Assets/SVGs/XPIcon";

const ItemData = [
  {
    img: <StarIcon />,
    heading: "Super Like ",
    content: `Fancy a super-like? You're in luck! Just go to the wife of your choice, and press the glowing "Super-Like" button!`,
  },
  {
    img: <MessageIcon />,
    heading: "Courtyard Session",
    content:
      "Find someone you like, start a conversation and lets see where it leads with our courtyard feature.",
  },
  {
    img: RingIcon,
    heading: "Wife Token",
    content:
      "The Wife Token is a virtual token that can be used to purchase in-app products and services.",
  },
  {
    img: <XPIcon />,
    heading: "Experience Points",
    content:
      "Earn experience points by completing daily challenges and tasks to increase your experience and earn rewards along the way.",
  },
];

const Features = () => {
  return (
    <ContainerDiv>
      <HeadingDiv>
        <p>
          <span>Features</span>
        </p>
        <h1>Our Core Features</h1>
      </HeadingDiv>

      <ContentDiv>
        {ItemData.map((item, index) => {
          return (
            <ItemDiv key={index}>
              <div className="imgDiv">
                {index === 2 ? (
                  <img src={item.img} alt="ring" />
                ) : (
                  <>{item.img}</>
                )}
              </div>
              <h3>{item.heading}</h3>
              <p>{item.content}</p>
            </ItemDiv>
          );
        })}
      </ContentDiv>
    </ContainerDiv>
  );
};

export default Features;
