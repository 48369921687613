import styled from "styled-components";

export const Nav = styled.nav`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;

  &.hideNav {
    display: none;
  }

  @media screen and (max-width: 768px) {
    height: 3rem;
  }

  & .btnDiv {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const ToggleDiv = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
