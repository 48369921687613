import styled from "styled-components";

export const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
  display: flex;
  gap: 6px;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding: 0.7rem 1rem;
  background: linear-gradient(90deg, #9e89fe 0%, #c38dfd 49.59%, #e391ff 100%);
  color: #ffffff;
`;
