import styled from "styled-components";
import BG from "./Assets/Images/BG.png";
import MblBG from "./Assets/Images/MblBG.png";

export const JoinWaitlistDiv = styled.div`
  background: url(${BG});
  background-size: cover;
  padding: 3rem 0%;
  border-radius: 20px;
  margin: 6rem 7.5% 0 7.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 248px;

  @media screen and (max-width: 768px) {
    margin: 0;
    background: url(${MblBG});
    background-repeat: no-repeat;
    border-radius: 0;
  }

  h1 {
    text-align: center;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: clamp(24px, 4vw, 32px);
  }

  & .btnDiv {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    button {
      background: #090914;
      border-radius: 9px;
      color: #ffffff;
      font-weight: 600;
      width: 180px;
      height: 45px;
      cursor: pointer;
      font-size: 16px;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
`;
