import React from "react";

const StarIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="50" height="50" rx="25" fill="#5B9BFA" />
      <path
        d="M32.3904 36.4946C32.2061 36.4953 32.0243 36.4536 31.8603 36.3731L25.9827 33.4234L20.1052 36.3731C19.9143 36.4693 19.6991 36.5122 19.4841 36.497C19.269 36.4818 19.0627 36.4091 18.8886 36.2871C18.7146 36.1651 18.5797 35.9988 18.4993 35.8069C18.419 35.6151 18.3964 35.4056 18.4341 35.202L19.5865 28.9824L14.8384 24.5634C14.6903 24.4217 14.5852 24.244 14.5344 24.0494C14.4837 23.8547 14.4891 23.6505 14.5503 23.4587C14.6171 23.2623 14.7399 23.0879 14.905 22.9551C15.07 22.8223 15.2705 22.7365 15.4838 22.7074L22.0528 21.7905L24.9455 16.1232C25.0399 15.9364 25.1872 15.7789 25.3707 15.6687C25.5541 15.5585 25.7662 15.5 25.9827 15.5C26.1992 15.5 26.4113 15.5585 26.5948 15.6687C26.7782 15.7789 26.9256 15.9364 27.0199 16.1232L29.9472 21.7795L36.5162 22.6964C36.7295 22.7255 36.93 22.8112 37.095 22.944C37.2601 23.0768 37.3829 23.2513 37.4497 23.4476C37.5109 23.6395 37.5163 23.8437 37.4656 24.0383C37.4148 24.2329 37.3097 24.4106 37.1616 24.5524L32.4135 28.9713L33.5659 35.191C33.6071 35.3981 33.5855 35.6122 33.5038 35.8079C33.4221 36.0037 33.2837 36.1729 33.1049 36.2957C32.8962 36.4359 32.645 36.5058 32.3904 36.4946Z"
        fill="white"
      />
      <rect
        x="1"
        y="1"
        width="50"
        height="50"
        rx="25"
        stroke="#5B9BFA"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default StarIcon;
