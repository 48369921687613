import styled from "styled-components";

export const ContainerDiv = styled.div`
  background: #13151e;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 6rem 5%;
`;

export const HeadingDiv = styled.div`
  p {
    text-align: center;

    span {
      width: fit-content;
      background: rgba(251, 250, 225, 0.2);
      border-radius: 200px;
      padding: 12px 20px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
    }
  }

  h1 {
    text-align: center;
    color: white;
    font-family: "Poppins", sans-serif;
    margin-top: 1.5rem;
  }
`;

export const ContentDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 5rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;

  & .imgDiv {
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    color: white;
    font-family: "Poppins", sans-serif;
  }

  p {
    text-align: center;
    color: white;
    font-size: 14px;
  }
`;
