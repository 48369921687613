import React from "react";

// styling
import { Button } from "./NavButton.styled";

// SVGs
import ArrowIcon from "../Assets/SVGs/ArrowIcon";

const NavButton = ({ FormUrl }) => {
  return (
    <Button onClick={() => window.open(FormUrl, "_blank")}>
      <span>Join the waitlist</span>
      <ArrowIcon />
    </Button>
  );
};

export default NavButton;
