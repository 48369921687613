import styled from "styled-components";

// Images
// import iphoneImg from "./Assets/Images/iphone.png";

export const ContainerDiv = styled.div`
  display: flex;
  width: 100%;
  background-size: cover;
  background-position: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    background: white;
    height: fit-content;
    align-items: flex-end;
    padding: 10% 0;
  }
`;

export const ContentDiv = styled.div`
  &.contentDiv {
    padding-left: 5%;
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0 8%;
    }
  }

  br {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  h1 {
    font-weight: 700;
    color: #0f172a;
    font-family: "Poppins", sans-serif;
    font-size: clamp(25px, 4vw, 50px);
    line-height: clamp(25px, 6vw, 68px);

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: clamp(30px, 6vw, 50px);
      line-height: clamp(35px, 8vw, 68px);
    }
  }

  h1 span {
    font-style: italic;
    background: linear-gradient(90deg, #9e89fe 0%, #c38dfd 49.59%, #e391ff 100%),
      linear-gradient(0deg, #0f172a, #0f172a);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  & .heading {
    font-size: clamp(25px, 4vw, 50px);
    line-height: clamp(25px, 6vw, 68px);

    @media screen and (max-width: 768px) {
      font-size: clamp(30px, 6vw, 50px);
      line-height: clamp(35px, 8vw, 68px);
    }
  }

  p {
    color: #475569;
    margin-top: 1rem;
  }

  & .btnsDiv {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-top: 3vh;
    height: fit-content;

    & .videobtn {
      display: flex;
      align-items: center;
      gap: 13px;

      p {
        font-weight: 600;
        color: #0f172a;
      }
    }
  }
`;

export const SideImage = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ImageDiv = styled.div`
  width: 100%;
  height: fit-content;
  display: none;
  position: relative;

  img {
    width: 100%;
    margin-bottom: -4px;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
